<template>
	<div v-loading.fullscreen.lock="fullscreenLoading" class="pdf-images">
		<img src="../../../../assets/csiiPdf/csii1.png">
		<img src="../../../../assets/csiiPdf/csii2.png">
	</div>
</template>

<script>
export default {
	data() {
		return {
			fullscreenLoading: false
		};
	},
	mounted() {
		this.openFullScreen1();
	},
	methods: {
		openFullScreen1() {
			this.fullscreenLoading = true;
			setTimeout(() => {
				this.fullscreenLoading = false;
			}, 1000);
		},
	}
};
</script>

<style>
	.pdf-images{
		width: 100%;
		float: left;
	}
	.pdf-images img{
		width: 100%;
		float: left;
	}
</style>
